export function calc_single(barriers,failures, item){ //item.external+ item.code_cmp+item.failure_coef
	let taux=100;
	var x = ";" + String(item.cmp_code) + ";";
	let brs =barriers.filter((a) => {
			return a.chemin.match(x);
			});
	brs.forEach(barrier=>{

		let brs = barrier.chemin.split(";");
		let  ord_value = 1;
		let external = -1;
		let ord_total=0;
		let ord=0;
		let failure_taux = 0;
		let i=failures.findIndex(f=>f.id==item.failure_id);

			if(!failures[i].external) failures[i].external=-1;
			let issue="";
			brs.forEach((b) => {
				if(b!=""){
				if (ord_value == 1) ord = 3;
				else if (ord_value == 2) ord = 2;
				else if (ord_value == 3) ord = 1;
				else ord = 0.5;
				ord_value++;
					if (external < 0)
                      {

						if (parseInt(b) == parseInt(item.cmp_code)) {
							issue=issue+b+failures[i].code;
							external = failures[i].external?failures[i].external:-1;
							failure_taux = failure_taux + (ord * failures[i].value);
						} else {failure_taux = failure_taux + (ord * 100);}
						ord_total = ord_total + ord;
					}
				}

			});

		failure_taux = Math.round(failure_taux / ord_total);
		if(failure_taux<taux) {

				taux=failure_taux;
				item.failure_taux=taux;
				item.barrier=issue;

			}
	});

 return item;
}
function  get_min(cmp_code,test_failures) {
     let f=test_failures.filter(x=>(x.cmp_code)==(cmp_code) && x.failure_id!=-1);


     if(f.length>0){
       return f.reduce(function (prev, current) {
          if (+current.failure_taux < +prev.failure_taux) {
            return current;
          } else {
            return prev;
          }
        });
     } else return null;
    }

export  function get_multiples(well_failures,test_failures,barriers, failures,id){
	let multiples=[];

     let tests =[];
     if(test_failures)  tests = Object.assign([], test_failures);
     if(well_failures){
		well_failures.forEach(x=>{
			let k=tests.findIndex(y=>y.cmp_code==x.cmp_code && y.g_id==x.g_id);
			if(k<0)
			tests.push({
                    id:x.id,
					welltest_id: x.welltest_id,
					failure_id: x.failure_id,
					cmp_code: x.cmp_code,
					g_id:x.g_id,
					testtype: x.testtype,
                    barrier:x.barrier,
                    failure_taux:x.failure_taux,
                    statut_id:x.statut_id,
                    cmp_id:x.cmp_id,

			});
		});
	}
    let fls=[]; //unique cmps
    let acives_barriers=[]; //only matching barriers

       tests.forEach(f=>{
           let exists=false;
           let k=fls.findIndex(x=>x.cmp_code==f.cmp_code);
           if(k>=0){
                  if(f.failure_taux<fls[k].failure_taux){
                           fls[k]=f;
                            exists=true;
                  }
           }
           if(!exists) fls.push(f) ;
       });
       barriers.forEach(b=>{
            let exist=false;
            fls.forEach(f=>{
                  if(b.chemin.match(";"+f.cmp_code+";")) exist=true;
            });
                   if(exist)acives_barriers.push(b);
         });

      acives_barriers.forEach(b=>{

      let brs=b.chemin.split(";");
      //  vars description de la multipe
            // let xtree={des:"", cnt:0};
			// let head={des:"", cnt:0};
			// let down={des:"", cnt:0};
      //
     // pour chaque barrier on calcule
       let  ord_value = 1;
       let external = -1;
       let ord_total=0;
       let ord=0;
       let cnt=0;
       let taux=0;
       let issue="";
	brs.forEach(br=>{
			if(br!=""){
				if (ord_value == 1) ord = 3;
				else if (ord_value == 2) ord = 2;
				else if (ord_value == 3) ord = 1;
				else ord = 0.5;
                ord_total = ord_total + ord;
				ord_value++;
				//exists failure for this cmp

				let k=get_min(br,tests);

				if(k && k.failure_id){
					let i=failures.findIndex(x=>x.id==k.failure_id);
                    //---
					if(i>=0)
					{
						issue=issue+br+failures[i].code;
                            //  if(k.testtype==3) xtree.cnt=xtree.cnt+1; else
                            //      if(k.testtype==5) head.cnt=head.cnt+1;else down.cnt=down.cnt+1;
						cnt++;
						if (external < 0) {
							if (parseInt(br) == parseInt(k.cmp_code)) {

								external = failures[i].external?failures[i].external:-1;
								taux = taux + (ord * failures[i].value);
							} else {
								taux = taux + (ord * 100);
							}
						}
					}
				}else {
						taux = taux + (ord * 100);
						//issue=issue+br;
				}

			}

		});
// let description="";
// if (xtree.cnt>0)  description= "Xtree("+xtree.cnt+") ";
// if (head.cnt>0)  description=description+ "Head("+head.cnt+") ";
// if (down.cnt>0)  description=description+ "Down("+down.cnt+") ";
         taux = Math.round(taux/ord_total);
             let m = {
                id:null,
				welltest_id: id,
				statut_id: 1,
				failure_taux: taux,
				barrier: issue,
				failure_id: 0,
				cmp_code: "0",
                multiple:"Multiple failures",
			};

	if(taux<100 &&  cnt>1)
        //pour le cas on insere plusieurs multiples sinon juste la plus severe
		//if(taux<100 && multiples.findIndex(a=>a.barrier==issue)<0 && cnt>1)
         //  {
			// 		let index=0;
            //         let ctl=false;
			// 		multiples.forEach(mi=>{
			// if(mi.barrier.match(issue)) ctl=true; else
            //                    if(issue.match(mi.barrier)){
			// 					multiples.splice(index,1);
            //                    }
			// 		index++;
			// 		});
            //    if(!ctl)
            multiples.push(m);
          // }
	});

       return multiples;
}

export  function repaire_failure(well_failures,barriers){
	let multiples=[];
    let fls=[]; //unique cmps
    let acives_barriers=[]; //only matching barriers

       well_failures.forEach(f=>{
           let exists=false;
           let k=fls.findIndex(x=>x.cmp_code==f.cmp_code);
           if(k>=0){
                  if(f.failure_taux<fls[k].failure_taux){
                           fls[k]=f;
                            exists=true;
                  }
           }
           if(!exists) fls.push(f) ;
       });
       barriers.forEach(b=>{
            let exist=false;
            fls.forEach(f=>{
                  if(b.chemin.match(";"+f.cmp_code+";")) exist=true;
            });
                   if(exist)acives_barriers.push(b);
         });

      acives_barriers.forEach(b=>{

      let brs=b.chemin.split(";");
      //
     // pour chaque barrier on calcule
       let  ord_value = 1;
       let external = -1;
       let id=-1;
       let ord_total=0;
       let ord=0;
       let cnt=0;
       let taux=0;
       let issue="";
	brs.forEach(br=>{
			if(br!=""){
				if (ord_value == 1) ord = 3;
				else if (ord_value == 2) ord = 2;
				else if (ord_value == 3) ord = 1;
				else ord = 0.5;
                ord_total = ord_total + ord;
				ord_value++;
				//exists failure for this cmp

				let k=get_min(br,well_failures);

				if(k && k.failure_id){
					let i=well_failures.findIndex(x=>x.failure_id==k.failure_id);
                    //---
					if(i>=0)
					{
						issue=issue+br+well_failures[i].code;
                        id=well_failures[i].test_id;
						cnt++;
						if (external < 0) {
							if (parseInt(br) == parseInt(k.cmp_code)) {

								external = well_failures[i].external?well_failures[i].external:-1;
								taux = taux + (ord * well_failures[i].value);
							} else {
								taux = taux + (ord * 100);
							}
						}
					}
				}else {
						taux = taux + (ord * 100);
						//issue=issue+br;
				}

			}

		});

         taux = Math.round(taux/ord_total);

             let m = {
                id:null,
				welltest_id: id,
				statut_id: 1,
				failure_taux: taux,
				barrier: issue,
				failure_id: 0,
				cmp_code: "0",
                multiple:"Multiple failures",
			};
        if(taux<100 &&  cnt>1)
		//if(taux<100 && multiples.findIndex(a=>a.barrier==issue)<0 && cnt>1)
           {
			// 		let index=0;
            //         let ctl=false;
			// 		multiples.forEach(mi=>{
			// if(mi.barrier.match(issue)) ctl=true; else
            //                    if(issue.match(mi.barrier)){
			// 					multiples.splice(index,1);
            //                    }
			// 		index++;
			// 		});
            //    if(!ctl)
			multiples.push(m);

           }
	});

       return multiples;
}